@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./assets/font/PlusJakartaSans-Bold.woff2") format("woff2"),
    url("./assets/font/PlusJakartaSans-Bold.woff") format("woff"),
    url("./assets/font/PlusJakartaSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./assets/font/PlusJakartaSans-ExtraBold.woff2") format("woff2"),
    url("./assets/font/PlusJakartaSans-ExtraBold.woff") format("woff"),
    url("./assets/font/PlusJakartaSans-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./assets/font/PlusJakartaSans-ExtraLight.woff2") format("woff2"),
    url("./assets/font/PlusJakartaSans-ExtraLight.woff") format("woff"),
    url("./assets/font/PlusJakartaSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./assets/font/PlusJakartaSans-Light.woff2") format("woff2"),
    url("./assets/font/PlusJakartaSans-Light.woff") format("woff"),
    url("./assets/font/PlusJakartaSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./assets/font/PlusJakartaSans-Medium.woff2") format("woff2"),
    url("./assets/font/PlusJakartaSans-Medium.woff") format("woff"),
    url("./assets/font/PlusJakartaSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./assets/font/PlusJakartaSans-Regular.woff2") format("woff2"),
    url("./assets/font/PlusJakartaSans-Regular.woff") format("woff"),
    url("./assets/font/PlusJakartaSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./assets/font/PlusJakartaSans-SemiBold.woff2") format("woff2"),
    url("./assets/font/PlusJakartaSans-SemiBold.woff") format("woff"),
    url("./assets/font/PlusJakartaSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "PlusJakartaSans" !important;
  @apply overflow-x-hidden;
}
/* html{
   @apply overflow-x-hidden
} */

h1,
.h1 {
  @apply text-52 font-700 leading-1.5;
}

h2,
.h2 {
  @apply text-44 font-700 leading-1.5;
}

h3,
.h3 {
  @apply text-32 font-600 leading-1.5;
}

h4,
.h4 {
  @apply text-28 font-600 leading-1.5;
}

h5,
.h5 {
  @apply text-24 font-500 leading-1.5;
}

h6,
.h6 {
  @apply text-22 font-500 leading-1.5;
}

p {
  @apply text-16 font-500 leading-1.5;
}

html {
  background: #141316;
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1270px;
  }
}

table.transaction-table tbody tr td:first-child {
  @apply rounded-tl-md rounded-bl-md;
}

table.transaction-table tbody tr td:last-child {
  @apply rounded-tr-md rounded-br-md;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.qr-wrapper canvas {
  @apply !w-full !h-full;
}

.theme-scrollbar::-webkit-scrollbar {
  @apply w-1;
}

.theme-scrollbar::-webkit-scrollbar-track {
  @apply w-1;
}

.theme-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-theme;
}
.custom-otp-input-wrapper input + span {
  @apply hidden;
}
.custom-otp-input-wrapper input {
  @apply focus:!bg-transparent focus:border focus:border-theme !w-[40px] !h-[35px] sm:!w-[60px] sm:!h-[50px] rounded-sm outline-none bg-transparent bg-light dark:bg-dark-600 text-dark-950 dark:text-white;
}
.custom-otp-input-wrapper > div {
  @apply flex items-center justify-center gap-[5px] sm:gap-4;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.section-gap {
  @apply py-14 xl:py-20;
}

.pagination {
  @apply flex items-center justify-end gap-2 py-3 pb-6;
}
.pagination li {
  @apply text-white text-xs;
}
.pagination li a {
  @apply w-6 h-6 inline-flex items-center justify-center hover:dark:border-theme hover:border-dark-950 transition-all duration-150 border !bg-white dark:text-white text-black dark:!bg-dark-950 dark:border-dark-800 border-dark-800/20;
}
.pagination li.active a {
  @apply dark:!border-theme  !border-dark-950;
}
.catpcha_style div iframe {
  transform: scale(0.9);
}
@media (min-width: 425px) {
  .catpcha_style div iframe {
    transform: scale(1);
  }
}

.custom-slider {
  .swiper-wrapper {
    align-items: center !important;
    .swiper-slide {
      height: 100%;
    }
  }
}

.none-prev {
  display: none;
}

.none-next {
  display: none;
}

.policy-content-page{
  min-height: 100vh;
  background-color: #ffffff !important;
  padding: 40px  ;
}
.policy-content-page p,
.policy-content-page ul li{
color: #141316 !important;
}
.policy-content-page p strong{
font-size: 20px;
margin-bottom: 8px !important;
display: inline-block;
}
